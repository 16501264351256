<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo style="width: 100px;height: 40px;"/>
        </b-link>

        <b-card-title class="mb-1"> ورود به پنل مدیریتی </b-card-title>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- username -->
            <b-form-group label="نام کاربری" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false : null"
                  name="register-username"
                  placeholder="نام کاربری"
                />
                <small class="text-danger">{{
                  errors[0] ? "پر کردن فیلد الزامی است" : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">کلمه عبور</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="کلمه عبور"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? "پر کردن فیلد الزامی است" : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              class="d-flex justify-content-center align-items-center"
              block
              :disabled="invalid || isLoading"
              @click="login()"
            >
              ورود به حساب کاربری
              <b-spinner
                v-show="isLoading"
                class="position-absolute"
                small
                type="grow"
              />
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BSpinner,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.isLoading = true
      window.localStorage.setItem("phone_number", "");
      axios
        .post("/api/v1/admin/auth/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            this.isLoading =false
            let phone_number_string = response.data.data.phone_number;
            window.localStorage.setItem("phone_number", phone_number_string);
            router.push("/auth/otp");
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
            },
          });
        });
    },
  },
};
</script>

<style scope lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.card-title {
  display: flex;
  justify-content: center;
}
</style>
